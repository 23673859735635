import useCookieStorage from './useCookieStorage';

type UserLoggedStatus = 'logged_in' | 'guest';

export const useUserLoggedStatus = (): { user_logged_status: UserLoggedStatus } => {
  const [isCustomerLoggedIn] = useCookieStorage('isCustomerLoggedIn', false);

  return {
    user_logged_status: isCustomerLoggedIn ? 'logged_in' : 'guest',
  };
};
