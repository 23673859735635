import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { truncateText } from 'lib/helpers/functions/TextTruncate';
import { SearchIcon } from 'components/SvgIcons/SearchIcon';
import useSessionStorage from 'hooks/useSessionStorage';
import useRtl from 'hooks/useRtl';

interface Props {
  Icon?: any | undefined;
  text?: string | undefined;
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  className?: string;
  placeHolder?: string;
  defaultOption?: { label: string; value: string | number; key: string | number } | undefined;
  options?: { label: string; value: string | number; key: string | number }[];
  wrapperClassName?: string;
  error?: string;
  value?: string;
  oldDesign?: boolean;
  name: string;
}

const CustomSelectInput = ({
  Icon,
  onChange = () => {},
  className = '',
  defaultOption,
  wrapperClassName = '',
  placeHolder,
  options,
  text,
  error,
  value,
  oldDesign = false,
  name,
}: Props) => {
  const { t } = useTranslation('common');
  const [classToggle, setClassToggle] = useState('light-gray font-medium');
  const [iconColor, setIconColor] = useState('#757575');
  const [open, setOpen] = useSessionStorage('selectState', { [name]: false });
  const [data, setData] = useState<any>([]);
  const [inputText, setInputText] = useState('');
  const isRtl = useRtl();

  const updateProperties = (obj, name) => {
    const result = {};
    for (const key in obj) {
      if (key !== name) {
        result[key] = false;
      }
    }
    return result;
  };

  const handleChange = (e: any) => {
    setClassToggle('dark-gray font-bold');
    setIconColor('#595959');
    onChange(e);
  };

  useEffect(() => {
    setData(options);
  }, [options]);

  useEffect(() => {
    if (name && !open.hasOwnProperty(name)) {
      setOpen((prev) => ({ ...prev, [name]: false }));
    } else {
      setOpen((prev) => ({ ...prev, [name]: false }));
    }
  }, [name]);

  useEffect(() => {
    document.body.addEventListener('click', () => {
      setOpen((prev) => ({ ...updateProperties(prev, ''), [name]: false }));
    });
  });

  useEffect(() => {
    if (defaultOption) {
      setClassToggle('dark-gray font-bold');
      setIconColor('#595959');
    }
  }, [defaultOption]);

  const filterData = data?.filter((item: any) => {
    return item.label.toLowerCase().includes(inputText.toLowerCase());
  });

  const showSearch = options && options?.length > 4;

  if (oldDesign)
    return (
      <div className={`relative w-full h-10 bg-apollo-dark-white ${error ? 'mb-5' : ''} ${wrapperClassName}`}>
        {Icon && (
          <span className={`absolute inset-y-0 left-0 flex items-center px-2 ${isRtl ? '' : 'left-0'}`}>
            <Icon color={iconColor} />
          </span>
        )}
        {text && <span className='absolute inset-y-0 left-0 flex items-center pl-2 pb-7 text-[12px]'>{text}</span>}
        <div className='select-wrapper pr-1'>
          <select
            value={value}
            onChange={handleChange}
            className={`${
              isRtl ? 'custom-select-input-rtl' : 'custom-select-input'
            } py-2 outline-none block placeholder-light-gray w-full h-10 bg-apollo-dark-white rounded ${
              error ? 'border border-apollo-error' : ''
            } ${Icon ? 'px-10' : 'px-2'} text-sm text-${classToggle} ${className}`}
          >
            {!defaultOption && placeHolder && (
              <option selected value=''>
                {placeHolder}
              </option>
            )}
            {defaultOption && (
              <option selected key={defaultOption?.key} value={defaultOption?.value}>
                {defaultOption?.label}
              </option>
            )}
            {options?.map((item) => (
              <option key={item?.key} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
        </div>
        {error && (
          <div className='block mt-1 mx-1 text-xs text-[10px] text-apollo-error text-left rtl:text-right'>
            {t(error)}
          </div>
        )}
      </div>
    );

  return (
    <div
      className={`relative`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={`h-[55px] bg-apollo-dark-white p-2 rounded rounded-b-none ${
          error ? 'border-2 border-b-apollo-error' : 'border-2 border-b-primary'
        }`}
      >
        <div className={`relative w-full h-10 bg-apollo-dark-white ${error ? 'mb-5' : ''} ${wrapperClassName}`}>
          <div
            // className={`absolute rtl:left-[0px] ${
            //   Icon ? 'left-[32px] rtl:right-[32px]' : ''
            // } top-[-2px] text-[12px] px-2 ${error ? 'text-apollo-error' : 'text-primary'}`}
            className={`absolute ${
              Icon ? 'left-[32px] rtl:right-[32px]' : 'rtl:right-[32px]'
            } rtl:left-[0px] w-full top-[-2px] text-[12px] rtl:text-right px-2 ${
              error ? 'text-apollo-error' : 'text-primary'
            }`}
          >
            {t(placeHolder || '')}
          </div>
          {Icon && (
            <span className={`absolute inset-y-0 left-0 flex items-center  ${isRtl ? 'pr-3 right-0  w-9' : ''}`}>
              <Icon color={iconColor} />
            </span>
          )}
          {text && <span className='absolute inset-y-0 flex items-center pl-2 pb-7 text-[12px]'>{text}</span>}
          <div
            className='select-wrapper pr-1 cursor-pointer'
            onClick={() => {
              setOpen((prev) => ({ ...updateProperties(prev, name), [name]: !prev[name] }));
            }}
          >
            <div
              className={`${isRtl ? 'custom-select-input-right' : 'custom-select-input'} pt-[12px] rtl:text-right overflow-hidden outline-none block placeholder-light-gray w-full h-10 bg-apollo-dark-white rounded  ${
                Icon ? 'px-10' : 'px-2 rtl:px-10'
              } text-sm ${className}`}
            >
              {value ? truncateText(data?.find((item: any) => item?.value === value)?.label, 20) : t(placeHolder || '')}
            </div>
            {/* <select
            value={value}
            onChange={handleChange}
            className={`custom-select-input pt-[12px] outline-none block placeholder-light-gray w-full h-10 bg-apollo-dark-white rounded ${
              Icon ? 'px-10' : 'px-2'
            } text-sm text-${classToggle} ${className}`}
            >
            {!defaultOption && placeHolder && (
              <option selected value=''>
              {t(placeHolder)}
              </option>
              )}
              {defaultOption && (
                <option selected key={defaultOption?.key} value={defaultOption?.value}>
                {defaultOption?.label}
                </option>
                )}
                {options?.map((item) => (
                  <option key={item?.key} value={item?.value}>
                  {item?.label}
                  </option>
                  ))}
                </select> */}
          </div>
          {/* {error && (
          <div className='block mt-1 mx-1 text-xs text-[10px] text-apollo-error text-left rtl:text-right'>{t(error)}</div>
        )} */}
        </div>
      </div>
      {open[name] && (
        <div className='h-auto max-h-[200px] overflow-x-auto border-2 border-primary border-t-0 rounded z-10 w-full bg-apollo-dark-white absolute'>
          {showSearch && (
            <div
              className={`p-[6px] rtl:p-[6px] w-[100%] border-2 bg-apollo-dark-white border-b-primary absolute top-0 h-[44px]`}
            >
              <div className='relative rounded-lg border-transparent bg-[#f7f7f8] flex items-center'>
                <SearchIcon />
                <input
                  className='border-none bg-transparent mx-2 placeholder:text-body placeholder:text-[rgba(60,60,67,0.6)] outline-none w-[100%] text-dark-gray'
                  placeholder={t('search') || ''}
                  autoFocus
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                />
              </div>
            </div>
          )}
          <div className={`overflow-x-auto h-auto max-h-[200px] ${showSearch ? 'pt-11' : ''}`}>
            {filterData?.length > 0 ? (
              filterData?.map((item: any) => (
                <div
                  key={item?.key}
                  className={`p-2 hover:bg-apollo-primary hover:text-white cursor-pointer border-2 border-b-white ${
                    item?.value === value ? 'bg-apollo-primary text-white' : ''
                  }`}
                  onClick={() => {
                    handleChange({ target: { value: item?.value } });
                    setOpen((prev) => ({ ...updateProperties(prev, name), [name]: !prev[name] }));
                  }}
                >
                  {item?.label}
                </div>
              ))
            ) : (
              <div className='p-2 text-center'>{t('no_data_found')}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomSelectInput;
