import { SVG } from 'lib/interface';

export const NationalityIcon = ({ color = '#757575' }: SVG) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.3 7.7C10.2074 7.79152 10.1338 7.90052 10.0836 8.02068C10.0334 8.14084 10.0076 8.26977 10.0076 8.4C10.0076 8.53022 10.0334 8.65915 10.0836 8.77931C10.1338 8.89948 10.2074 9.00848 10.3 9.1L12.2 11H3C2.45 11 2 11.45 2 12C2 12.55 2.45 13 3 13H12.2L10.3 14.9C10.2074 14.9915 10.1338 15.1005 10.0836 15.2207C10.0334 15.3408 10.0076 15.4698 10.0076 15.6C10.0076 15.7302 10.0334 15.8592 10.0836 15.9793C10.1338 16.0995 10.2074 16.2085 10.3 16.3C10.69 16.69 11.31 16.69 11.7 16.3L15.29 12.71C15.3827 12.6175 15.4563 12.5076 15.5064 12.3866C15.5566 12.2657 15.5824 12.136 15.5824 12.005C15.5824 11.874 15.5566 11.7443 15.5064 11.6234C15.4563 11.5024 15.3827 11.3925 15.29 11.3L11.7 7.7C11.6085 7.60736 11.4995 7.53381 11.3793 7.48361C11.2592 7.43341 11.1302 7.40756 11 7.40756C10.8698 7.40756 10.7408 7.43341 10.6207 7.48361C10.5005 7.53381 10.3915 7.60736 10.3 7.7ZM20 19H13C12.45 19 12 19.45 12 20C12 20.55 12.45 21 13 21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3H13C12.45 3 12 3.45 12 4C12 4.55 12.45 5 13 5H20V19Z'
      fill={color}
    />
  </svg>
);
